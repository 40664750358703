import { FC, useContext, useEffect, useState } from "react";
import { Divider, Tooltip } from "@mui/material";
import { IMemberAttributes, IUserAttributes } from "@emovid/payloads-library";
import { AuthContext } from "../../Contexts/AuthContext";
import { displayNameForUser } from "../../Util/UserInfoMap";

interface RecipientsListProps {
    creator: IUserAttributes;
    members: IMemberAttributes[];
}

const RecipientsList: FC<RecipientsListProps> = ({ creator, members }) => {
    const authContext = useContext(AuthContext);
    const [creatorRecipients, setCreatorRecipients] = useState<IMemberAttributes[]>([]);
    const [otherRecipients, setOtherRecipients] = useState<IMemberAttributes[]>([]);

    useEffect(() => {
        let creatorRecipientsArr: IMemberAttributes[] = [];
        let otherRecipientsArr: IMemberAttributes[] = [];
        members.forEach((member: IMemberAttributes) => {
            if (!member.added_by_id || member.added_by_id === creator.id) creatorRecipientsArr.push(member);
            else otherRecipientsArr.push(member);
        });
        setCreatorRecipients(creatorRecipientsArr);
        setOtherRecipients(otherRecipientsArr);
    }, [members]);

    return (
        <>
            <Divider className="text-white">Creator</Divider>
            <div className="mt-3">
                <div title={displayNameForUser(creator!)}>{displayNameForUser(creator)}</div>
                <div className="font-light-color text-truncate" title={creator.email}>
                    {creator.email}
                </div>
            </div>
            <Tooltip title="Original recipients">
                <Divider className="mt-3 text-white">Recipients</Divider>
            </Tooltip>
            {(creatorRecipients || []).map((member: IMemberAttributes, i: number) => {
                return (
                    <Tooltip key={i} title={`Added by ${authContext.user?.email === creator.email ? "You" : displayNameForUser(creator!)}`}>
                        <div className="mt-3">
                            <div className="text-truncate" title={authContext.user?.email === member.UserDetails?.email ? "You" : displayNameForUser(member.UserDetails!)}>
                                {authContext.user?.email === member.UserDetails?.email ? "You" : displayNameForUser(member.UserDetails!)}
                            </div>
                            <div className="font-light-color text-truncate" title={member.UserDetails?.email}>
                                {member.UserDetails?.email}
                            </div>
                        </div>
                    </Tooltip>
                );
            })}
            {otherRecipients.length > 0 && (
                <Tooltip title="Recipients added later">
                    <Divider className="mt-3 text-white">Recipients +</Divider>
                </Tooltip>
            )}
            {(otherRecipients || []).map((member: IMemberAttributes, i: number) => {
                return (
                    <Tooltip key={i} title={`Added by ${authContext.user?.email === member.AddedBy?.email ? "You" : displayNameForUser(member.AddedBy!)}`}>
                        <div className="mt-3">
                            <div className="text-truncate" title={authContext.user?.email === member.UserDetails?.email ? "You" : displayNameForUser(member.UserDetails!)}>
                                {authContext.user?.email === member.UserDetails?.email ? "You" : displayNameForUser(member.UserDetails!)}
                            </div>
                            <div className="font-light-color text-truncate">{member.UserDetails?.email}</div>
                        </div>
                    </Tooltip>
                );
            })}
            <div>&nbsp;</div>
        </>
    );
};

export default RecipientsList;
