import { FC, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as amplitude from "@amplitude/analytics-browser";
import { Backdrop, CircularProgress, MenuItem } from "@mui/material";
import { Paths } from "./AppRoutes";
import { AuthContext } from "../Contexts/AuthContext";
import { deleteStorageItem, getStorageItem, setStorageItem, whitelistUser } from "./Helper";
import Login from "../Components/Login/Login";
import Popup from "./Popup";

interface TryNowButtonProps {
    className?: string;
    buttonText?: string;
    isMenuButton?: boolean;
}

const TryNowButton: FC<TryNowButtonProps> = (props: TryNowButtonProps) => {
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);
    const [loading, setLoading] = useState<boolean>(false);
    const promoCode = "try_now_signup";
    const shouldRedirectToInboxKey = `redirectToInboxAfterResponseLogin`;
    const shouldWhitelistUserKey = `whitelistUserAfterResponseLogin`;
    const [isSignupOpen, setSignupOpen] = useState<boolean>(false);

    useEffect(() => {
        if (authContext.user?.userId && getStorageItem(shouldWhitelistUserKey)) signupComplete();
        deleteStorageItem(shouldWhitelistUserKey);
    }, [authContext.user]);

    const callToActionClicked = () => {
        if (authContext.user?.userId) whitelistLoggedinUser();
        else {
            sessionStorage.setItem("try_now_signup_called", "true");
            setStorageItem(shouldWhitelistUserKey, true);
            setSignupOpen(true);
        }
    };

    const signupComplete = () => {
        setSignupOpen(false);
        whitelistLoggedinUser();
    };

    const whitelistLoggedinUser = () => {
        if (authContext.user) {
            setLoading(true);
            whitelistUser(authContext.user.email, authContext.user.name, promoCode).then(response => {
                authContext.setWhitelisted(); // should happen without re-login and without fetching whitelist status
                setLoading(false);
                deleteStorageItem(shouldRedirectToInboxKey);
                deleteStorageItem(shouldWhitelistUserKey);
                let identify = new amplitude.Identify();
                identify.setOnce("initial_promo_code", promoCode);
                identify.postInsert("promo_codes", promoCode);
                amplitude.identify(identify);
                navigate(`${Paths.myInbox}?welcome=true`);
            });
        }
    };

    return (
        <>
            {!props.isMenuButton && (
                <button className={props.className || "btn btn-primary"} onClick={callToActionClicked}>
                    {props.buttonText || "Try Now"}
                </button>
            )}
            {props.isMenuButton && <MenuItem onClick={callToActionClicked}>{props.buttonText || "Try Now"}</MenuItem>}
            <Backdrop sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
                {loading && <CircularProgress color="inherit" />}
            </Backdrop>
            <Popup isOpen={isSignupOpen} onClose={() => setSignupOpen(false)} className="login-popup">
                <Login handleClose={signupComplete} showSignupByDefault={true} title="Signup for Exclusive Access" />
            </Popup>
        </>
    );
};

export default TryNowButton;
