import { FC, useState, useEffect } from "react";
import { useQuery } from "react-query";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import { Button, CircularProgress, IconButton } from "@mui/material";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { IEventAttributes, IMemberAttributes, IPostAttributes, MemberType, Role } from "@emovid/payloads-library";
import { getSuggestedContacts, getTextWidth, isValidEmail, pushTagEvent } from "../../Common/Helper";
import AddEmailControl from "../../Common/AddEmailControl";
import HttpService from "../../Services/HttpService";
import ToastService from "../../Common/ToastService";
import InfoTooltip from "../../Common/InfoTooltip";

interface EmovidRecipientsProps {
    event: IEventAttributes;
    post: IPostAttributes;
    privateReplies?: boolean;
    recipientMode?: boolean;
    suggestions?: any[];
    eventUpdated?: Function;
    sendCopyToSelfChanged?: Function;
    editable?: boolean;
    emailEditMode?: boolean;
    enableEmailEditMode?: Function;
    onBccModeToggle?: Function;
}

const EmovidRecipients: FC<EmovidRecipientsProps> = (props: EmovidRecipientsProps) => {
    const copyToSelfMember: IMemberAttributes = {
        id: "dummy",
        member_id: "dummy",
        event_id: "current",
        member_type: MemberType.BCC,
        UserDetails: { email: props.post?.Creator?.email || props.event.Creator?.email || "", role: Role.USER }
    };
    const [loading, setLoading] = useState<boolean>(false);
    const [bccModeEnabled, setBccModeEnabled] = useState<boolean>(props.privateReplies || false);
    const [sendCopyToSelfEnabled, setSendCopyToSelfEnabled] = useState<boolean>((props.post || (props.event?.CreatorPosts || [])[0]).send_copy || false);
    const [suggestedContacts, setSuggestedContacts] = useState<any[]>([]);
    const [newMemberIds, setNewMemberIds] = useState<string[]>([]);
    const [memberEmails, setMemberEmails] = useState<IMemberAttributes[]>([]);
    const [memberEmailInputWidth, setMemberEmailInputWidth] = useState<number>(0);
    const [selectedMemberEmail, setSelectedMemberEmail] = useState<any>(null);

    useEffect(() => {
        setupMembersFromEvent(props.event);
    }, [props.event]);

    useEffect(() => {
        setBccModeEnabled(props.privateReplies || false);
    }, [props.privateReplies]);

    const { refetch } = useQuery({
        queryKey: ["suggestedContacts"],
        refetchOnWindowFocus: false,
        retry: false,
        queryFn: () => getSuggestedContacts(props.post?.creator_id || props.event?.creator_id, props?.event?.id!),
        onError: (error: any) => {
            console.log(error); // fail silently
        },
        onSuccess: (data: any[]) => {
            const contacts = data.map(contact => {
                return { email: contact.UserB.email, name: contact.UserB.name };
            });
            setSuggestedContacts(contacts);
        }
    });

    const setupMembersFromEvent = (event: IEventAttributes) => {
        if (!event) return;
        let members: IMemberAttributes[] = [...(event.Members || [])];
        if (props.privateReplies)
            members = members.filter((member: IMemberAttributes) => {
                return (
                    (!props.recipientMode || member.added_by_id === props.post?.creator_id || props.post?.creator_id === props.event?.creator_id) &&
                    member.member_id !== props.post?.creator_id
                );
            });
        if (props.recipientMode && props.post?.creator_id !== props.event?.creator_id)
            members.splice(0, 0, {
                id: "creator",
                member_id: "creator",
                event_id: event.id || "",
                member_type: MemberType.TO,
                UserDetails: props.event?.Creator
            }); // needs to be added since emovid creator not in members list
        members = members.filter((member: IMemberAttributes) => member.member_id !== props.post?.creator_id);
        setMemberEmails(members);
    };

    const setupFromEventData = (eventData: IEventAttributes) => {
        setupMembersFromEvent(eventData);
        if (props.eventUpdated) props.eventUpdated(eventData);
    };

    const handleMemberDelete = (memberToDelete: IMemberAttributes, editMode: string = "") => {
        if (memberToDelete.id === copyToSelfMember.id) {
            toggleSendCopyToSelf();
            return;
        }
        setLoading(true);
        HttpService.delete(`/v1/event/${props.event.id}/member/${memberToDelete.member_id}/delete`, null, true)
            .then(data => {
                setSelectedMemberEmail(null);
                setupFromEventData(data.event);
                setLoading(false);
                refetch();
                if (editMode) {
                    // @ts-ignore
                    document.getElementById(`${editMode}-email-input`).value = memberToDelete.UserDetails.email;
                    setTimeout(() => document.getElementById(`${editMode}-email-input`)?.focus(), 100);
                }
            })
            .catch(error => {
                setLoading(false);
                ToastService.alertableError(error.message || "Unable to delete recipient", error);
            });
    };

    const addEmailsToMainList = (emails: string[]) => {
        addEmailsInList(emails, MemberType.TO);
    };

    const toMemberEdit = (event: any, member: IMemberAttributes) => {
        if (props.editable && member.id !== copyToSelfMember.id) {
            event.preventDefault();
            setMemberEmailInputWidth(getTextWidth(member.UserDetails?.email || ""));
            handleMemberDelete(member, "to");
        }
    };

    const addEmailsInList = async (emails: string[], listType: MemberType) => {
        for (let i = 0; i < emails.length; i++) {
            const email = emails[i];
            if (!isValidEmail(email)) ToastService.userError("Please use a valid email");
            else if (props.event.Creator?.email === email && !sendCopyToSelfEnabled) toggleSendCopyToSelf();
            else if (isEmailPresentInMembersList(email)) ToastService.userError(`${email} is already on the recipient list!`);
            else await addEmailToMembersList(email, listType);
        }
        addEmailClicked();
    };

    const addEmailToMembersList = async (email: string, member_type: MemberType) => {
        setLoading(true);
        try {
            let addedMemberId = "";
            const data = await HttpService.putFormData(
                `/v1/event/${props.event.id}/member/add`,
                { UserDetails: { email }, member_type, added_by_id: props.post?.creator_id || props.event.creator_id, added_in_post_id: props.post.id },
                true
            );
            (data.event.Members || []).forEach((member: any) => {
                if (!member.UserDetails) {
                    member.UserDetails = { email };
                    addedMemberId = member.id;
                }
            });
            if (props.recipientMode) setNewMemberIds([...(newMemberIds || []), addedMemberId]);
            setupFromEventData(data.event);
            setLoading(false);
            setMemberEmailInputWidth(0);
            refetch();
        } catch (error: any) {
            setLoading(false);
            ToastService.alertableError(error.message, error);
        }
    };

    const handleMemberListBackKeyPressed = () => {
        if (!memberEmails.length) return;
        if (!selectedMemberEmail) {
            if (props.recipientMode && !newMemberIds.includes(memberEmails[memberEmails.length - 1].id || "")) {
                // do nothing
            } else setSelectedMemberEmail(memberEmails[memberEmails.length - 1]);
        } else handleMemberDelete(selectedMemberEmail);
    };

    const toEmailTextChanged = (e: any) => {
        setMemberEmailInputWidth(getTextWidth(e));
        setSelectedMemberEmail(null);
    };

    const isEmailPresentInMembersList = (email: string) => {
        let isPresent = false;
        (props.event.Members || []).forEach((member: any) => {
            if (member.UserDetails.email === email) {
                isPresent = true;
            }
        });
        if ([props.event.Creator?.email, "ai-test@emovid.com", "ai@emovid.com", "test@send-test.emovid.com"].includes(email)) return true;
        return isPresent;
    };

    const toggleSendCopyToSelf = () => {
        setLoading(true);
        const creatorPost = props.post || (props.event?.CreatorPosts || [])[0];
        HttpService.postFormData(`/v1/post/${creatorPost.id}/update`, { send_copy: !sendCopyToSelfEnabled }, false, true)
            .then(data => {
                setLoading(false);
                props.sendCopyToSelfChanged && props.sendCopyToSelfChanged(!sendCopyToSelfEnabled);
            })
            .catch(error => {
                setLoading(false);
                ToastService.alertableError(error.message || "Unable to update emovid", error);
            });
        setSendCopyToSelfEnabled(!sendCopyToSelfEnabled);
    };

    const isNewMember = (member: IMemberAttributes): boolean => {
        return member.added_in_post_id === props.post?.id;
    };

    const sendCopyToSelfClicked = () => {
        const creatorPost = props.post || (props.event?.CreatorPosts || [])[0];
        pushTagEvent("e_btn_send_copy_to_self", { event_id: props.event.id, post_id: creatorPost.id, send_copy: !sendCopyToSelfEnabled });
        toggleSendCopyToSelf();
    };

    const toggleBccModeClicked = () => {
        const creatorPost = props.post || (props.event?.CreatorPosts || [])[0];
        pushTagEvent("e_btn_bcc_mode_private", { event_id: props.event.id, post_id: creatorPost.id, bcc_mode_enabled: !bccModeEnabled });
        setBccModeEnabled(!bccModeEnabled);
        props.onBccModeToggle && props.onBccModeToggle();
    };

    const editRecipientsClicked = () => {
        const creatorPost = props.post || (props.event?.CreatorPosts || [])[0];
        pushTagEvent("e_btn_edit_recipients", { event_id: props.event.id, post_id: creatorPost.id });
        if (props.enableEmailEditMode) props.enableEmailEditMode();
        setTimeout(() => document.getElementById("to-email-input")?.focus(), 200);
    };

    const compressedEmailsText = (): string => {
        let topMembers = JSON.parse(JSON.stringify(memberEmails));
        let totalMembersCount = topMembers.length;
        topMembers = topMembers.splice(0, 1);
        if (totalMembersCount === 0) return `<span class='more-members'>${!sendCopyToSelfEnabled && memberEmails.length === 0 ? "Add Recipients" : ""}</span>`;
        let membersTextShort = topMembers[0].UserDetails.name || topMembers[0].UserDetails.email;
        if (totalMembersCount === 1) return membersTextShort;
        return `${membersTextShort} <span class="more-members">+ ${totalMembersCount - 1} more</span>`;
    };

    const addEmailClicked = () => {
        setTimeout(() => document.getElementById("to-email-input")?.focus(), 200);
    };

    function ChipForMember(props: { member: IMemberAttributes; editable: boolean }) {
        const { member, editable } = props;
        return (
            <Chip
                key={member.id}
                avatar={<Avatar>{member?.UserDetails?.email?.substring(0, 1)?.toUpperCase()}</Avatar>}
                label={member?.UserDetails?.email}
                variant="outlined"
                className={
                    "gray-bg-chip me-2 " + (bccModeEnabled ? "" : "text-white") + (selectedMemberEmail?.id === member.id ? " selected " : "") + (editable ? "" : " opacity-75 ")
                }
                deleteIcon={
                    editable ? (
                        <IconButton disabled={loading}>
                            <CloseIcon className={loading ? "" : "text-white"} />
                        </IconButton>
                    ) : (
                        <></>
                    )
                }
                onDoubleClick={event => (editable ? toMemberEdit(event, member) : null)}
                onDelete={() => (editable ? handleMemberDelete(member) : null)}
            />
        );
    }

    return (
        <>
            {!props.emailEditMode && (
                <Tooltip title={`Click to ${props.editable ? "add/edit" : "view"} recipients`}>
                    <table className="w-100">
                        <tbody>
                            <tr>
                                <td className="label-col">
                                    <div className={"pt-1 pb-1 label " + (bccModeEnabled ? "" : "text-white")}>{bccModeEnabled ? "Bcc:" : "To:"}</div>
                                </td>
                                <td>
                                    <div className="clickable" onClick={editRecipientsClicked}>
                                        {sendCopyToSelfEnabled && <span className="pe-2 opacity-50">{copyToSelfMember.UserDetails?.email}</span>}
                                        <span style={{ lineHeight: "28px" }} dangerouslySetInnerHTML={{ __html: compressedEmailsText() }}></span>
                                        {props.editable && <AddCircleRoundedIcon className="ms-2" />}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Tooltip>
            )}
            {props.emailEditMode && (
                <table className="w-100">
                    <tbody>
                        <tr>
                            <td className="label-col">
                                <div className={"pt-1 pb-1 pe-2 label " + (bccModeEnabled ? "" : "text-white")}>{bccModeEnabled ? "Bcc:" : "To:"}</div>
                            </td>
                            <td>
                                <div className="w-100 d-flex pt-1 contacts-chip-list flex-wrap" onClick={addEmailClicked}>
                                    {sendCopyToSelfEnabled && ChipForMember({ member: copyToSelfMember, editable: !props.recipientMode || false })}
                                    {memberEmails.map((member: IMemberAttributes, index: number) =>
                                        ChipForMember({
                                            member,
                                            editable: (props.editable && (props.recipientMode ? isNewMember(member) : true)) || false
                                        })
                                    )}
                                    {loading && <CircularProgress color="inherit" size={20} />}
                                    {props.editable && (
                                        <div className="last-child min-width-140px " style={memberEmailInputWidth > 20 ? { flexBasis: "100%" } : { flex: "1 1 0%" }}>
                                            <AddEmailControl
                                                id="to-email-input"
                                                addingEmail={loading}
                                                suggestions={suggestedContacts}
                                                onEmailsAdd={addEmailsToMainList}
                                                onBackKeyPressed={handleMemberListBackKeyPressed}
                                                onTextEntered={toEmailTextChanged}
                                                onBlur={() => setSelectedMemberEmail(null)}
                                            ></AddEmailControl>
                                        </div>
                                    )}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            )}
            <div className="d-flex flex-wrap">
                {!props.recipientMode && (
                    <Button className="d-flex mt-3 text-white" onClick={toggleBccModeClicked}>
                        <div className="me-2">
                            {bccModeEnabled && <img src="/images/checked.svg" alt="open" style={{ width: "20px" }} />}
                            {!bccModeEnabled && <img src="/images/unchecked.svg" alt="open" style={{ width: "20px" }} />}
                        </div>
                        <span>Bcc mode (Private)</span>
                    </Button>
                )}
                {!props.recipientMode && (
                    <div className="me-4 mt-3 pt-1">
                        <InfoTooltip text="Recipients added in the Bcc mode are invisible to all others and will NOT receive any reply emails from the other recipients." />
                    </div>
                )}
                <Button className="d-flex mt-3 text-white" onClick={sendCopyToSelfClicked}>
                    <div className="me-2">
                        {sendCopyToSelfEnabled && <img src="/images/checked.svg" alt="open" style={{ width: "20px" }} />}
                        {!sendCopyToSelfEnabled && <img src="/images/unchecked.svg" alt="open" style={{ width: "20px" }} />}
                    </div>
                    <span>Send Copy to Self</span>
                </Button>
            </div>
        </>
    );
};

export default EmovidRecipients;
