import { FC, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import { ClickAwayListener } from "@mui/material";
import { HtmlTooltip } from "./HtmlTooltip";

interface InfoTooltipProps {
    text: string;
}

const InfoTooltip: FC<InfoTooltipProps> = ({ text }: InfoTooltipProps) => {
    const [show, setShow] = useState<boolean>(false);

    return (
        <ClickAwayListener onClickAway={() => setShow(false)}>
            <HtmlTooltip
                title={
                    <div className="p-3 position-relative">
                        <button className="btn btn-link p-0 close-button position-absolute text-dark" style={{ right: 0 }} onClick={() => setShow(false)}>
                            <CloseIcon />
                        </button>
                        <div className="pt-2">{text}</div>
                    </div>
                }
                disableTouchListener
                open={show}
                onOpen={() => setShow(true)}
                onClose={() => setShow(false)}
            >
                <span onClick={() => setShow(true)}>
                    <HelpOutlineRoundedIcon fontSize="small" style={{ color: "white" }} />
                </span>
            </HtmlTooltip>
        </ClickAwayListener>
    );
};

export default InfoTooltip;
